import "./styles.scss"

import React from "react"

import IcoMobile from "assets/icons/mobile-contact.svg"
import IcoEnvelope from "assets/icons/envelope-contact.svg"

const Main = ({ data }) => {
  const team = [
    {
      image: require("assets/images/agnieszka_alingua.jpg").default,
      name: "Agnieszka Dąbek",
    },
    {
      image: require("assets/images/marta_alingua-scaled.jpg").default,
      name: "Marta Kowalczyk",
    },
    {
      image: require("assets/images/aneta_alingua-scaled.jpg").default,
      name: "Aneta Tokarska",
    },
    {
      image: require("assets/images/kinga_alingua-scaled.jpg").default,
      name: "Kinga Król",
    },
  ]

  return (
    <section className="contact-main">
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-xl-8">
            <h3>{data?.contactHeader}</h3>
            <div
              dangerouslySetInnerHTML={{ __html: data?.contactDescription }}
            />

            <div className="row contact-tile justify-content-center">
              <div className="col-md-4">
                <div className="contact-main__icon">
                  <div className="contact-main__icon-ico">
                    <img src={IcoMobile} alt="" />
                  </div>
                  <a href="tel:+48 884 004 934">+48 884 004 934</a>
                </div>
              </div>
              <div className="col-md-4">
                <div className="contact-main__icon">
                  <div className="contact-main__icon-ico">
                    <img src={IcoEnvelope} alt="" />
                  </div>
                  <a href="mailto:kontakt@alingua.pl">kontakt@alingua.pl</a>
                </div>
              </div>
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col-xl-10">
              <div className="row">
                {team?.map((item, index) => (
                  <div className="col-md-3 col-sm-6" key={index}>
                    <div className="contact-main__person">
                      <img src={item?.image} alt={item?.name} />
                      <h4>{item?.name}</h4>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Main
