import "./styles.scss"

import React from "react"

import Button from "components/Button"

import t from "locale"

const ThankYou = ({ lang, data }) => {
  return (
    <section className="thank-you-main">
      <div className="container-fluid">
        <img
          src={require("assets/icons/envelope-thank-you.svg").default}
          alt=""
        />
        <h2>{data?.confirmationHeader}</h2>
        <p>{data?.confirmationDescription}</p>
        <Button to={t("/", lang)}>{t("Strona główna", lang)}</Button>
      </div>
    </section>
  )
}

export default ThankYou
